<script>
import { routesData } from "@/route.config";
import StateStorage from "@/utils/StateStorage";
import patientService from "@/services/patientService";
import { CModal, CModalHeader, CModalBody, CModalFooter, CModalTitle } from "@coreui/vue";
import ModalInputItem from "../../../components/ui/ModalInputItem.vue";
import { isChild } from "@/utils/Helpers";

export default {
    name: "CreatePatientPage",
    components: {
        CModal,
        CModalHeader,
        CModalTitle,
        CModalBody,
        CModalFooter,
        ModalInputItem,
    },
    data() {
        return {
            isPageLoading: false,
            confirmPatientCreateModal: false,
            routes: routesData,
            phoneNumber: "",
            personType: "",
            isPatient: "",
            minDate: "",
            isValidCategory: true,
            categories: [],
            guideId: "",
            form: {
                last_name: "",
                first_name: "",
                phone_one: "",
                phone_two: "",
                sex: "",
                age: "",
                national_cardId: "",
                residence: "",
                date_of_birth: "",
                category: {
                    id: "",
                    name: "",
                },
                statusGuide: ""
            },

            isServiceInitialDataError: false,
            isCreatePatientError: false,
        };
    },
    methods: {
        format(date) {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        },
        handleChooseCategory(dob) {
            const isPatientaChild = isChild(dob);
            if(this.form.sex === "F" && isPatientaChild === false){
                // console.log("patient category is: femme enciente: ", this.categories[1]);
                this.form.category = this.categories[1];

            } else {
                this.form.category = this.categories[0];

                // console.log("patient category is: child", this.categories[0])

            }
        },
        handleSetMinDOBBaseOnSex(e) {
            const currentSex = e.target.value;
            if(currentSex === "M") {
                const currentDate = new Date();
                const tenYearsAgo = currentDate.setFullYear(currentDate.getFullYear() - 10);
                this.minDate = new Date(tenYearsAgo);
                // console.log("Value of minDate",new Date(tenYearsAgo) );
            } else {
                this.minDate = ""
            }
            this.form.date_of_birth = "";
            this.form.category = { id: "", name: "" }
           
        },
        async formHandler() {
            try {
                this.isCreatePatientError = false;
                this.isPageLoading = true;
                const payload = {
                    ...this.form,
                    phone_one: this.form.phone_one.toString(),
                    phone_two: this.form.phone_two.toString(),
                    age: null,
                    guide_id: parseInt(this.guideId),
                    category_id: this.form.category.id,
                    statusGuide: this.form.statusGuide
                };
                console.log(payload);

                let resData;
                let redirectURL = "";
                if (this.isPatient) {
                    resData = await patientService.createGuidePaitientProfile(payload);
                } else {
                    resData = await patientService.create(payload);
                }

                this.confirmPatientCreateModal = false;

                if (resData.isPresent) {
                    StateStorage.set("phone", this.phoneNumber);
                    redirectURL = `/patients/${resData.data.patient.id}?phone=${resData.data.phone}&guide_id=${resData.data.guide_id}`;

                    this.$router.push(redirectURL);
                } else {
                    this.isCreatePatientError = true;
                }
            } catch (error) {
                this.isCreatePatientError = true;
                console.log("Something went wrong,", error);
            } finally {
                this.isPageLoading = false;
            }
        },
        async getAllCategory() {
            try {
                this.isServiceInitialDataError = false;
                this.isPageLoading = true;
                const category_all = await patientService.getAllCategories();

                if (category_all) {
                    this.categories = category_all;
                }
            } catch (error) {
                this.isServiceInitialDataError = true;
            } finally {
                this.isPageLoading = false;
            }
        },
        toggleConfirmModal() {
            //   console.log("form data ", { ...this.form });
            if (this.form.category.name === "") {
                this.isValidCategory = false;
                console.log("category required");
            } else {
                this.isValidCategory = true;
                this.confirmPatientCreateModal = true;
            }
        },

        async getServiceInitialData() {
            await this.getAllCategory().then(async () => {
                // Check if the action to do is to create the guide's patient profile
                if (this.isPatient) {
                    // Get the guide data and set it in the form
                    const guideDatafromLS = await StateStorage.get("guide");
                    if (guideDatafromLS) {
                        this.form = {
                            last_name: guideDatafromLS.first_name,
                            first_name: guideDatafromLS.last_name,
                            phone_one: this.phoneNumber,
                            phone_two: "",
                            sex: guideDatafromLS.sex,
                            age: "",
                            national_cardId: "",
                            residence: guideDatafromLS.residence,
                            date_of_birth: guideDatafromLS.date_of_birth,
                            category: this.categories[1],
                            statusGuide: "Mon profile"
                        };
                    }
                }
                //   this.form.phone_one = this.phoneNumber;
            });
        },
    },
    async mounted() {
        this.personType = this.$route.query.person_type;
        this.guideId = this.$route.query.guide_id;
        const phone = this.$route.query.phone;
        this.isPatient = this.$route.query.is_patient;
        this.phoneNumber = phone;

        await this.getServiceInitialData();
    },
    computed: {
        filteredCategories() {
            // If isPatient is true, exclude the first item; otherwise, return all categories
            return this.isPatient ? this.categories.slice(1) : this.categories;
        },
    },
};
</script>
<template>
    <div class="flex flex-grow flex-col justify-center">
        <template v-if="isPageLoading">
            <content-section-loading />
        </template>
        <template v-else>
            <template v-if="isServiceInitialDataError">
                <error-handler-component :submit-handler="getServiceInitialData" />
            </template>
            <template v-else>
                <template v-if="isCreatePatientError">
                    <error-handler-component :submit-handler="formHandler" />
                </template>
                <template v-else>
                    <form @submit.prevent="toggleConfirmModal"
                        class="flex flex-grow flex-col justify-between px-2 pb-4 pt-8">
                        <div class="flex flex-grow flex-col ">
                            <div>
                                <h1 class="mb-2 text-3xl font-bold text-black">
                                    <span v-if="!isPatient">Enregistrement d’un nouveau patient</span>
                                    <span v-else>Création d'un profil patient</span>
                                </h1>
                                <h2 class="text-xl font-semibold text-black">
                                    <span v-if="!isPatient">
                                        Veuillez remplir les champs ci-dessous pour enregistrer un
                                        nouveau patient
                                    </span>
                                    <span v-else>
                                        procéder à la création de votre profil patient
                                    </span>
                                </h2>
                                <div class="mt-4 flex flex-col gap-y-4 flex-grow ">
                                    <!-- First Name -->
                                    <div v-if="!isPatient" class="flex flex-col gap-x-2">
                                        <label class="font-bold" for="first_name">Prénom <span
                                                class="text-red-400">*</span></label>
                                        <input v-model="form.first_name" type="text"
                                            class="text-md w-full rounded border border-slate-500 bg-slate-50 px-2 py-2 leading-6"
                                            name="first_name" id="first_name" required
                                            placeholder="Entrez votre prénom" />
                                    </div>

                                    <!-- Last Name -->
                                    <div v-if="!isPatient" class="flex flex-col gap-x-2">
                                        <label class="font-bold" for="last_name">Nom de famille <span
                                                class="text-red-400">*</span></label>
                                        <input v-model="form.last_name" type="text"
                                            class="text-md w-full rounded border border-slate-500 bg-slate-50 px-2 py-2 leading-6"
                                            name="last_name" id="last_name" required
                                            placeholder="Entrez votre nom de famille" />
                                    </div>

                                    <!-- Sex -->
                                    <div v-if="!isPatient" class="flex flex-col gap-x-2">
                                        <label class="font-bold" for="sex">Sexe </label>
                                        <select :onchange="handleSetMinDOBBaseOnSex"  v-model="form.sex"
                                            class="text-md w-full rounded border border-slate-500 bg-slate-50 px-2 py-2 leading-6"
                                            name="sex" id="sex" required placeholder="Choisissez votre sexe">
                                            <option value="" disabled selected>
                                                Choisissez votre sexe
                                            </option>
                                            <option value="M">Homme</option>
                                            <option value="F">Femme</option>
                                            <!-- <option value="Other">Prefer not to say</option> -->
                                        </select>
                                    </div>

                                    <!-- Date of birth -->
                                    <div v-if="!isPatient" class="flex flex-col gap-x-2">
                                        <label class="font-bold" for="date_of_birth">Date de naissance
                                            <span class="text-red-400">*</span></label>
                                        <VueDatePicker @update:model-value="handleChooseCategory"
                                            v-model="form.date_of_birth" model-type="dd/MM/yyyy"
                                            placeholder="Cliquez pour choisir une date" :format="format" required
                                            :enable-time-picker="false" :max-date="new Date()" :min-date="minDate" />
                                    </div>
                                    <!-- <div> Catergory: {{ form.category }} </div> -->
                                    <!-- category -->
                                    <!-- <div class="flex flex-col gap-x-2">
                                        <label v-if="!isPatient" class="font-bold" for="category">
                                            Sélectionnez votre catégorie
                                            <span class="text-red-500">*</span>
                                        </label>
                                        <select required v-model="form.category" id="category"
                                            class="text-md w-full rounded border border-slate-500 bg-slate-50 px-2 py-2 leading-6">
                                            <option disabled selected :value="{ id: '', name: '' }">
                                                Choisissez votre catégorie
                                            </option>
                                            <template v-if="categories.length !== 0">
                                                <option v-for="category in filteredCategories" :key="category.id"
                                                    :value="category">
                                                    {{ category.name }}
                                                </option>
                                            </template>
                                            <template v-else>
                                                                                            <option disabled value="">
                                                                                                Aucune catégories trouvée
                                                                                            </option>
                                                                                        </template>
                                            </select>
                                            <h5 class="p-1 text-sm text-red-500" v-if="!isValidCategory">
                                                la catégorie est obligatoire
                                            </h5>
                                            </div> -->
                                    <!-- phones -->
                                    <div v-if="!isPatient" class="flex flex-col gap-x-2">
                                        <label class="font-bold" for="phone_one">Numéro de téléphone d’urgence
                                            <span class="text-red-400">*</span></label>
                                        <input type="number" v-model="form.phone_one"
                                            class="text-md w-full rounded border border-slate-500 bg-slate-50 px-2 py-2 leading-6"
                                            name="phone_one" id="phone_one" required
                                            placeholder="Entrez un numéro de téléphone" />
                                    </div>

                                    <!-- National ID Card -->
                                    <!-- <div v-if="!isPatient" class="flex flex-col gap-x-2">
                                        <label class="font-bold" for="national_cardId">Numéro de CNI </label>
                                        <input :disabled="isPatient === '1'" v-model="form.national_cardId" type="text"
                                            class="text-md w-full rounded border border-slate-500 bg-slate-50 px-2 py-2 leading-6"
                                            name="national_cardId" id="national_cardId" :required="personType === '1'"
                                            placeholder="Entrez votre numéro de CNI" />
                                    </div> -->

                                    <!-- Parental Link -->
                                    <div v-if="!isPatient" class="flex flex-col gap-x-2">
                                        <label class="font-bold" for="sex">
                                            <span>Lien parental</span>
                                            <span class="text-red-400 ml-1">*</span>
                                        </label>
                                        <select v-model="form.statusGuide"
                                            class="text-md w-full rounded border border-slate-500 bg-slate-50 px-2 py-2 leading-6"
                                            name="parentail_link" id="parentail_link" required
                                            placeholder="Choisissez votre lien parental">
                                            <option value="" disabled selected>
                                                Choisissez votre lien parental
                                            </option>
                                            <option value="Pere/Mere">Pere/Mere</option>
                                            <option value="Uncle/Tante">Uncle/Tante</option>
                                            <option value="Tuteur/Tutresse">Tuteur/Tutresse</option>
                                            <option value="Frere/Seour">Frere/Seour</option>

                                        </select>
                                    </div>

                                    <!-- Residence address -->
                                    <div v-if="!isPatient" class="flex flex-col gap-x-2">
                                        <label class="font-bold" for="residence">Adresse de résidence
                                            <span class="text-red-400">*</span></label>
                                        <input :disabled="isPatient === '1'" v-model="form.residence" type="text"
                                            class="text-md w-full rounded border border-slate-500 bg-slate-50 px-2 py-2 leading-6"
                                            name="residence" id="residence" required
                                            placeholder="Entrez votre adresse de résidence" />
                                    </div>
                                </div>
                                <div class="mt-4 flex flex-col justify-end gap-y-2">
                                    <button type="submit"
                                        class="btn btn-primary bg-primary btn-md bg-blue-600 py-2 text-white">
                                        Enregistrer
                                    </button>
                                    <back-button class="cursor-pointer" :href="isPatient
                                        ? `/patients?phone=${phoneNumber}&guide_id=${guideId}&is_patient=0`
                                        : `/patients?phone=${phoneNumber}&guide_id=${guideId}`
                                        "></back-button>
                                </div>
                            </div>
                        </div>

                    </form>
                    <template v-if="true">
                        <CModal alignment="center" scrollable backdrop="static" :visible="confirmPatientCreateModal"
                            @close="() => {
                                confirmPatientCreateModal = false;
                            }
                                " aria-labelledby="VerticallyCenteredExample">
                            <CModalHeader>
                                <CModalTitle id="VerticallyCenteredExample">
                                    Confirmer les informations renseignées
                                </CModalTitle>
                            </CModalHeader>
                            <CModalBody>
                                <div>
                                    <modal-input-item label="Prénom" :value="form.first_name" />
                                    <modal-input-item label="Nom de famille" :value="form.last_name" />
                                    <modal-input-item label="Sexe" :value="form.sex" />
                                    <modal-input-item label="Catégorie" :value="form.category.name" />
                                    <modal-input-item label="Date de naissance" :value="form.date_of_birth" />
                                    <modal-input-item label="Numéro de téléphone d’urgence" :value="form.phone_one" />

                                    <modal-input-item v-if="!isPatient" label="Lien parental"
                                        :value="form.statusGuide" />
                                    <!-- <modal-input-item label="National ID Number" :value="form.national_cardId" /> -->
                                    <modal-input-item label="Adresse de résidence" :value="form.residence" />
                                </div>
                            </CModalBody>
                            <CModalFooter>
                                <div class="d-grid w-full gap-y-2">
                                    <button @click="formHandler" class="btn btn-success text-white">
                                        Enregistrer
                                    </button>
                                    <button class="btn btn-info text-white" @click="() => {
                                        confirmPatientCreateModal = false;
                                    }
                                        ">
                                        Modifier
                                    </button>
                                </div>
                            </CModalFooter>
                        </CModal>
                    </template>
                </template>
            </template>
        </template>
    </div>
</template>
